import React from 'react';
import { Sector } from 'recharts';
import { formatCurrency } from '../../utils';

const RenderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  // the padding of the active section
  const height = 5;

  return (
    <g>
      <text x={cx} y={cy} dy={-6} textAnchor="middle" fill="gray">
        {payload.title}
      </text>
      <text x={cx} y={cy} dy={16} textAnchor="middle" fill="black" fontWeight="bold" fontSize={14}>
        {formatCurrency(payload.value)} ₽
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius - 1}
        outerRadius={outerRadius + height}
        fill={fill}
      />
    </g>
  );
};

export default RenderActiveShape;
