import React, { useState, useRef } from 'react';
import { Carousel } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import './balance.css';

const BalanceCard = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isBalanceHidden, setIsBalanceHidden] = useState(false);
  const carouselRef = useRef(null);

  const balanceTypes = [
    { type: 'Total Balance', amount: '$0.00', showGains: true },
    { type: 'Investing Balance', amount: '$0.00', showGains: true },
    { type: 'Wallet Balance', amount: '$0.00', showWallet: true },
  ];

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handleSlideChange = (current) => {
    setCurrentSlide(current);
  };

  const toggleBalanceVisibility = () => {
    setIsBalanceHidden(!isBalanceHidden);
  };

  const renderBalanceAmount = (amount) => {
    return isBalanceHidden ? '******' : amount;
  };

  return (
    <div className="balance-card-container">
      <div className="swipeable-card">
        <div className="nav-button-container">
          <button
            className={`nav-button prev ${currentSlide === 0 ? 'hidden' : ''}`}
            onClick={handlePrev}
          >
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowBackIosIcon">
              <path d="M11.67 3.87 9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"></path>
            </svg>
          </button>
          <button
            className={`nav-button next ${currentSlide === balanceTypes.length - 1 ? 'hidden' : ''}`}
            onClick={handleNext}
          >
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowForwardIosIcon">
              <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
            </svg>
          </button>
        </div>
        <Carousel
          ref={carouselRef}
          afterChange={handleSlideChange}
          dots={false}
        >
          {balanceTypes.map((balance, index) => (
            <div key={index}>
              <div className="card-content">
                <div className="balance-header">
                  <span className="balance-label">{balance.type}</span>
                  <button 
                    className="hide-balance-button" 
                    onClick={toggleBalanceVisibility}
                  >
                    <svg 
                      className="visibility-icon"
                      focusable="false" 
                      aria-hidden="true" 
                      viewBox="0 0 24 24" 
                      width="24"
                      height="24"
                    >
                      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3" />
                      <line x1="3" y1="3" x2="21" y2="21" className={`eye-slash ${isBalanceHidden ? 'visible' : ''}`} />
                    </svg>
                  </button>
                </div>
                <p className="balance-amount">{renderBalanceAmount(balance.amount)}</p>
                <hr className="balance-divider" />
                {balance.showGains && (
                  <div className="total-gains">
                    <span className="gains-label">Total Gains</span>
                    <span className="gains-amount">
                      <ArrowUpOutlined className="gains-icon" />
                      <span>0%</span>
                    </span>
                  </div>
                )}
                {balance.showWallet && (
                  <a href="/wallet" className="wallet-link">
                    <span>Open Wallet</span>
                    <svg viewBox="0 0 24 24" focusable="false" aria-hidden="true">
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                    </svg>
                  </a>
                )}
              </div>
            </div>
          ))}
        </Carousel>
        <div className="balance-tabs">
          {balanceTypes.map((_, index) => (
            <span
              key={index}
              className={`balance-tab-dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => carouselRef.current.goTo(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;