import React, { useEffect, useRef, useState } from "react";
import { Badge, Dropdown, Menu, Button } from "antd";
import { logout } from '../../API/authService';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import MenuButton from "../MenuButton";
// import { logoutAction } from '../../store/actions';

const notificationItems = [
    {
        key: '1',
        label: (
            <a rel="notif" href={'/'}>
                Notification 1
            </a>
        ),
    },
    {
        key: '2',
        label: (
            <a rel="notif" href={'/'}>
                Notification 2
            </a>
        ),
    },
    {
        key: '3',
        label: (
            <a rel="notif" href={'/'}>
                Notification 3
            </a>
        ),
    },
];

const invoiceItems = [
    {
        key: '11',
        label: (
            <a rel="inv" href={'/'}>
                comment test 1
            </a>
        ),
    },
    {
        key: '22',
        label: (
            <a rel="inv" href={'/'}>
                comment test 2
            </a>
        ),
    },
    {
        key: '33',
        label: (
            <a rel="inv" href={'/'}>
                comment test 3
            </a>
        ),
    },
];

function AppHeader() {
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [commentsOpen, setCommentsOpen] = useState(false);
    const headerRef = useRef();
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const handleNotificationsClick = () => {
        setNotificationsOpen(!notificationsOpen);
    };

    const handleCommentsClick = () => {
        setCommentsOpen(!commentsOpen);
    };

    const handleLogout = () => {
        logout();
        localStorage.clear('token')
        //   dispatch(logoutAction());
        navigate('/login');
    }
    
    useEffect(() => {
        const action = () => {
            if (headerRef.current) {
                if (window.scrollY > 0) {
                    headerRef.current.classList.add("AppHeader--fixed");
                } else {
                    headerRef.current.classList.remove("AppHeader--fixed");
                }
            }
        };
        
        document.addEventListener("scroll", action);

        return () => {
            document.removeEventListener("scroll", action);
        };
    }, []);
    
    return (
        <div className="AppHeader" ref={headerRef}>
            <MenuButton />

            <Dropdown
                trigger={["click"]}
                visible={notificationsOpen}
                onVisibleChange={handleNotificationsClick}
                placement="bottom"
                overlay={(
                    <Menu className="app-header-menu">
                        <ul className="app-header-ul">
                            {notificationItems.map(item => (
                                <li key={item.key}>{item.label}</li>
                            ))}
                        </ul>
                    </Menu>
                )}
            >
                <Badge count={2} style={{ marginRight: 5, marginTop: 3 }}>
                    <img
                        src="./statistics-page-images/Notification.svg"
                        alt="notification"
                        className="badge-img"
                    />
                </Badge>
            </Dropdown>

            <Dropdown
                trigger={["click"]}
                visible={commentsOpen}
                onVisibleChange={handleCommentsClick}
                placement="bottomLeft"
                overlay={(
                    <Menu className="app-header-menu">
                        <ul className="app-header-ul">
                            {invoiceItems.map(item => (
                                <li key={item.key}>{item.label}</li>
                            ))}
                        </ul>
                    </Menu>
                )}
                className="headerDropDown"
            >
                <Badge count={2} style={{ marginRight: 35, marginTop: 3 }}>
                    <img
                        src="./statistics-page-images/invoice.svg"
                        alt="invoice"
                        className="badge-img2"
                    />
                </Badge>
            </Dropdown>
            <Button onClick={handleLogout}> Logout</Button>
        </div>
    );
}

export default AppHeader;