import React from 'react';
import { Typography, Divider } from 'antd';
import './customer.css';

const { Title, Text } = Typography;

const CustomerCorner = () => {
  return (
    <div className="customer-corner">
      <Title level={4} className="customer-corner-title">Customer Corner</Title>
      <div className="customer-corner-items">
        <div className="customer-corner-item">
          <span className="icon-wrapper primary">
            <svg className="icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
              <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2"></path>
            </svg>
          </span>
          <span className="content">
            <Text strong>Contact Us</Text>
            <Text type="secondary">Chat with a member of our<br/> customer success team.</Text>
          </span>
          <svg className="arrow-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
            <path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z"></path>
          </svg>
          {/* <button className="overlay-button" type="button" data-test-id="contact-us">
            <span className="sr-only">Contact Us</span>
          </button> */}
        </div>
        <Divider />
        <div className="customer-corner-item">
          <span className="icon-wrapper primary">
            <svg className="icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
              <path d="M8 5v14l11-7z"></path>
            </svg>
          </span>
          <span className="content">
            <Text strong>Rise Onboarding Series</Text>
            <Text type="secondary">Watch our onboarding videos <br/>  to get started</Text>
          </span>
          <svg className="arrow-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
            <path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CustomerCorner;