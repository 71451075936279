import React from 'react';
import {formatNumber, getColorForNumber} from "../../utils";
import './financialResult.css'
import CustomTable from '../CustomTable/CustomTable';



const columns = [
    {
        title: ' ',
        dataIndex: 'stock',
        width: '3%',
        render:(text) => (
            <span className="stock-image">
                <img src='./statistics-page-images/financial_result_img.svg' alt='fin_result' className="stock-icon" />
            </span>
        ),
    },
     {
        title: 'АКТИВ',
        dataIndex: 'assets',
        width: '28%'
    },
    {
        title: 'ТИКЕР',
        dataIndex: 'ticker',
        render:(text) => <div style={{ fontWeight:500}}>{text}</div>,
        width: '28%'
    },
    {
        title: 'PNL',
        dataIndex: 'pnl',
        width: '28%',
        render:(num) => <div style={{color: getColorForNumber(num)}}> {formatNumber(num)} ₽ </div>

    },
];
const data = [
    {
        key: '1',
        ticker: 'LKOH',
        assets : 'ЛУКОЙЛ',
        pnl:-510.93
    },
    {
        key: '2',
        ticker: 'LKOH',
        assets : 'ЛУКОЙЛ',
        pnl:510.93
    },
    {
        key: '3',
        ticker: 'LKOH',
        assets : 'ЛУКОЙЛ',
        pnl:-510.93
    },
    {
        key: '4',
        ticker: 'LKOH',
        assets : 'ЛУКОЙЛ',
        pnl:510.93
    },
    {
        key: '5',
        ticker: 'LKOH',
        assets : 'ЛУКОЙЛ',
        pnl:510.93
    },


];

// rowSelection object indicates the need for row selection
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },

};
const FinancialResultSection = () => {
    return (
        <div className='stocks-container'>
            <div className='titles-alignment'>
                <span >Финансовый результат</span>
            </div>
            <div className='pnl-text'>
                Зафиксированный профит за период группировкой по активам учетом комиссии.
            </div>

            <CustomTable data={data} columns={columns} rowSelection={rowSelection}/>
        </div>

    );
};

export default FinancialResultSection;