import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Typography } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import RenderActiveShape from './RenderActiveShape';
import { formatCurrency } from '../../utils';

const { Title } = Typography;

const COLORS = ['#F762C4', '#8674FE', '#42CC7D', '#FFD76D', '#FF6B6B', '#FFD76D', '#FF6B6B', '#42CC7D', '#8674FE', '#F762C4'];

const CardComponent = ({ title, data, isLoading, formatter = (v) => v }) => {
	const [activeIndex, setActiveIndex] = useState();
	const currentTitle = `${data.count} ${title}`;
	const breakdown = data.breakdown.map((item, index) => ({
		name: formatter(item.name),
		title: currentTitle,
		value: Math.abs(item.value),
		percentage: ((item.value / data.total.value) * 100).toFixed(1),
		color: COLORS[index % COLORS.length]
	})).filter(item => {
		return parseFloat(item.percentage) !== 0;
	});

	const onPieEnter = useCallback(
		(_, index) => {
			setActiveIndex(index);
		},
		[setActiveIndex]
	);

	// set activeIndex to undefined when the user click outside the pie chart (the path of teh svg inside)
	useEffect(() => {
		const listener = (e) => {
			const className = e.target.className;
			if (typeof className === 'object' && className.baseVal.includes('analytic-pie')) {
				return;
			}
			setActiveIndex(undefined);
		};

		document.addEventListener('click', listener);
		return () => document.removeEventListener('click', listener);
	}, []);

	const { cx, cy } = { cx: '50%', cy: '50%' };

	return (
		<Card bordered={true} className='analytics-card-component'>
			<div className='analytics-sub'>
				<Title level={4} className='analytics-sub-title'>
					Аналитика «{title}»
				</Title>
				<Button type='text' size='middle' icon={<MoreOutlined />} />
			</div>
			<ResponsiveContainer width="100%" height={200} style={{ position: 'relative' }}>
				{
					isLoading
						? <div className='loading-overlay'>
							<LoadingOutlined className='loading-icon' />
						</div>
						: <PieChart className='analytic-pie-chart'>
							
							<Pie
								activeIndex={activeIndex}
								activeShape={RenderActiveShape}
								data={breakdown}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								innerRadius={50}
								outerRadius={65}
								onClick={onPieEnter}
								className='analytic-pie'
							>
								{breakdown.map((entry) => (
									<Cell key={entry.name} fill={entry.color} />
								))}
							</Pie>
							{
								activeIndex === undefined && (
									<g>
										<text x={cx} y={cy} dy={-6} textAnchor="middle" fill="gray">
											{currentTitle}
										</text>
										<text x={cx} y={cy} dy={16} textAnchor="middle" fill="black" fontWeight="bold" fontSize={14}>
											{formatCurrency(data.total.value)} ₽
										</text>
									</g>
								)
							}
						</PieChart>
				}
			</ResponsiveContainer>
			<div className='pie-chart-legends-container'>
				{
					breakdown.map((item) => (
						<div key={item.name} className='pie-chart-legend'>
							<span className='pie-chart-legend-lideco' style={{ backgroundColor: item.color }} />
							<div>
								{item.name}  {item.percentage}%
							</div>
						</div>
					))
				}
			</div>
		</Card>
	);
}

export default CardComponent;
