import { DatePicker, Form, Input, InputNumber, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import dayjs from 'dayjs';
import _ from 'lodash';
import { current } from '@reduxjs/toolkit';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    type,
    suffix,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const isEmpty = checkEmpty();
    const [editing, setEditing] = useState(isEmpty);

    function checkEmpty() {
        return (record && dataIndex
            ? type === 'number'
                ? _.isNil(record[dataIndex])
                : _.isEmpty(record[dataIndex])
            : false);
    }

    const enableEditMode = (value) => {
        setEditing(true);
        if (inputRef.current)
            inputRef.current.focus();
        toggleEdit(value);
    };

    const disableEditMode = (value) => {
        setEditing(!value);
        toggleEdit(value);
    }

    const toggleEdit = (v) => {
        const value = typeof v === 'object' ? record[dataIndex] : v;
        form.setFieldsValue({
            [dataIndex]: value,
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            disableEditMode(values[dataIndex]);
            if (values[dataIndex] !== record[dataIndex]) {
                handleSave(record, { [dataIndex]: values[dataIndex] });
            }

            // if (Object.keys(values).some(key => values[key] !== record[key])) {
            //     handleSave(record, values);
            // }
        } catch (errInfo) {
            console.error('Save failed:', errInfo);
        }
    };

    const saveDate = (date, dateString) => {
        if (!dateString) {
            return;
        }

        const value = dayjs.utc(dateString, 'DD.MM.YYYY').toISOString();
        disableEditMode(value);
        if (value !== record[dataIndex]) {
            handleSave(record, { [dataIndex]: value });
        }
    }

    const handleDatePickerBlur = (ev) => {
        if (ev.relatedTarget === null) {
            disableEditMode();
        }
    }

    const getInput = () => {
        switch (type) {
            case 'number':
                return <InputNumber className='editable-table-input' ref={inputRef} onPressEnter={save} onBlur={save} suffix={suffix} min={restProps.minnumber} />
            case 'password':
                return <Input.Password className='editable-table-input' autoComplete="false" ref={inputRef} onPressEnter={save} onBlur={save} />
            default:
                return <Input className='editable-table-input' ref={inputRef} onPressEnter={save} onBlur={save} suffix={suffix} />
        }
    }

    let childNode = children;
    const value = record && dataIndex ? record[dataIndex] : null;

    if (editable) {
        childNode = editing ? (
            type !== 'date' ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: false,
                            message: isEmpty ? 'Field required' : `${title} is required.`,
                        },
                    ]}
                >
                    {getInput()}
                </Form.Item>
            ) : <DatePicker
                className='editable-table-input eti-picker'
                defaultValue={value ? dayjs.utc(value) : null}
                format="DD.MM.YYYY"
                ref={inputRef}
                onChange={saveDate}
                onBlur={handleDatePickerBlur} />
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={enableEditMode}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const EditableTable = (props) => {
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    return (
        <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            {...props}
        />
    );
};

export default EditableTable;