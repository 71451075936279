import DashboardContent from "../../Component/TrackrAppDashboard/DashboardContent";
import TrackrDashboardLayout from "../../Component/TrackrDashboardLayout";


const TrackrApp = () => {
  return (
    <TrackrDashboardLayout>
      <DashboardContent />
    </TrackrDashboardLayout>
  );
};

export default TrackrApp;