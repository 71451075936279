import "./App.css";
import {Route, Routes} from "react-router-dom";
import WelcomePage from "./Pages/WelcomePage";
import ResetPasswordPage from './Pages/ResetPasswordPage';
import SendResetPasswordPage from './Pages/SendResetPasswordPage';
import DashboardBoardContainer from "./Component/Layout/DashboardBoardContainer";
import RequireAuth from "./Component/RequireAuth";
import WhyTradeTrackr from "./Pages/WhyTradetrackr/index";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import PrivacyPolicy from "./Pages/Privacy-Policy";
import TermsConditions from "./Pages/TermsConditions";
import Disclosure from "./Pages/Disclosure";
import TrackrApp from "./Pages/TrackApp/TrackrApp";

function App() {
    return (
        <Routes>
            <Route path="/trackr-home" element={<TrackrApp />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/disclosure" element={<Disclosure />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/register" element={<WelcomePage/>}></Route>
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/reset-password" element={<ResetPasswordPage/>} />
            <Route path="/send-reset-password" element={<SendResetPasswordPage/>} />
            <Route path="/why-tradetrackr" element={<WhyTradeTrackr/>} />
            <Route path="/" element={<RequireAuth/>}>
                <Route index element={<DashboardBoardContainer/>} />
                <Route path="*" element={<DashboardBoardContainer/>} />
            </Route>
        </Routes>
    );
}

export default App;